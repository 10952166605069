<template>
  <Column class="guide-view__tabs">
    <slot/>
  </Column>
</template>

<script>
export default {
  name: 'GuideAccordion',
};
</script>
